import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ContentModalStyles.css'
import { Button, Tabs, Select } from 'antd';
import { 
  SearchOutlined, 
  CloseCircleOutlined, 
  CloseOutlined,
  PlaySquareTwoTone 
} from '@ant-design/icons';
import { YOUTUBE_API_KEY, AUTH_API } from "../config";

const ContentModal = ({ 
  closeModal, 
  handleItemClick, 
  handleSearchInputChange,
  searchText,
  myTeam,
  modalContent,
  accessToken, 
  meetingId,
  handleOpenIframeModal,
  handleCloseIframeModal
  }) => {
  const [activeTab, setActiveTab] = useState('youtube');
  const [youtubeResults, setYoutubeResults] = useState([]);
  const [youtubeSearching, setYoutubeSearching] = useState(false);
  const [showVideoPreviewModal, setShowVideoPreviewModal] = useState(false);
  const [currentPreviewVideo, setCurrentPreviewVideo] = useState(null);
  const [isContentLoading, setIsContentLoading] = useState(false);
  const [studentVideos, setStudentVideos] = useState([]);
  const [studentId, setStudentId] = useState(0);

  useEffect(() => {
    window.addEventListener("message", handlePostMessage);
    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  }, []);

  const handlePostMessage = (event) => {
    if (event.origin === AUTH_API) {
      if (event.data?.name === "CloseVideoAnnotationiFrame") {
        handleCloseIframeModal();
      }
    }
    return;
  };

  const handleYoutubePreview = (video) => {
    setCurrentPreviewVideo(video);
    setShowVideoPreviewModal(true);
  };

  const handleClosePreviewModal = () => {
    setShowVideoPreviewModal(false);
    setCurrentPreviewVideo(null);
  };
  
  const handleSearch = () => {
    if (activeTab === 'youtube') {
      searchYoutubeVideos();
    }
  };

  const handleSearchOnEnter = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const tabItems = [
    {
      key: 'youtube',
      label: `Youtube`,
      children: <>
        {!youtubeSearching?
          youtubeResults.length > 0?
          <ul className="content-list">
            {youtubeResults.map(video => (
              <li key={video.id.videoId} >
                <img onClick={() =>handleItemClick({type :'youtube', url : `https://www.youtube.com/watch?v=${video.id.videoId}`}) } src={video.snippet.thumbnails.medium.url} alt={video.snippet.title} />
                <span className="content-title">{video.snippet.title}</span>
                <button className="preview-button" onClick={() => handleYoutubePreview(video)}>
                  Preview
                </button>
              </li>
            ))}
          </ul> : <center>{"No results found!"}</center>
        : <center>{"Searching Youtube..."}</center>}
      </>,
    }
  ];

  const searchYoutubeVideos = () => {
    const maxResults = 100;
    const searchQuery = searchText.trim();
    setYoutubeSearching(true);
    axios
      .get('https://www.googleapis.com/youtube/v3/search', {
        params: {
          key: YOUTUBE_API_KEY,
          part: 'snippet',
          q: searchQuery,
          maxResults: maxResults,
          type: 'video',
          videoEmbeddable: true,
          //videoCategoryId: '27',
          safeSearch: 'strict'
        },
      })
      .then((response) => {
        console.log(response.data.items);
        setYoutubeResults(response.data.items);
        setYoutubeSearching(false);
      })
      .catch((error) => {
        setYoutubeSearching(false);
        console.error('Error fetching YouTube videos:', error);
      });
  };

  const handleStudentChange = (studentId) => {
    setStudentId(studentId);
    handleGetStudentVideos(studentId);
  }

  const handleGetStudentVideos = (studentId) => {
    setStudentVideos([]);
    setIsContentLoading(true);
    axios.post(`${AUTH_API}/api/get_student_videos_for_vc`, 
      {
        token : accessToken,
        meetingId : meetingId,
        student_id	: studentId,     
      }, 
      { headers : { "Content-Type": "multipart/form-data" } })
    .then(function (response) {
      const videos = response?.data?.result?.data?.videos ?? [];
      setStudentVideos(videos);
      setIsContentLoading(false);
    })
    .catch(function (error) {
      console.log(error);
    });
  };
  
  return (
    <div className="modal">
      <div className="modal-content">
        <Button
          className="closeBtn"
          onClick={closeModal}
          icon={<CloseCircleOutlined />}
        />
        <h2>{modalContent}</h2>
        {modalContent === 'Player Videos' && (
          <>
          <div>
            <Select 
            defaultValue="Select a team member" 
            onChange={handleStudentChange}
            style={{ width: 200 }}>
              {/* <Option key={"all"} value={0}>
                All
              </Option> */}
              {myTeam.map((member) => (
                <Option key={member.student_id} value={member.student_id}>
                  {`${member.student_info?.first_name} ${member.student_info?.last_name}`}
                </Option>
              ))}
            </Select>
            {/* <Button
              onClick={() => handleGetStudentVideos()}
              icon={<SearchOutlined />}
            /> */}
          </div>
          <div>
          {!isContentLoading?
            studentVideos.length > 0?
            <ul className="content-list">
              {studentVideos.map((video, index) => (
                <li key={`${video.hwt_assignment_id}-${index}`} >
                  <PlaySquareTwoTone onClick={() =>handleItemClick({type :'video', url : video.getfile}) } style={{"fontSize":"100px"}} />
                  <span onClick={() =>handleItemClick({type :'video', url : video.getfile}) } title={video.assignment_title} className="content-title">{video.assignment_title}</span>
                  <span className="content-title date">{video.assignment_created_date}</span>
                  <Button size='small' type="primary" onClick={() => handleOpenIframeModal(`${AUTH_API}/video-annotation?token=${accessToken}&assignment_master_id=${video.hwt_assignment_id}&assignment_file_id=${video.assignment_file_id}&student_id=${studentId}&save_type=${video.save_type}`)}>
                    Annotate
                  </Button>
                </li>
              ))}
            </ul> : <center>{"No results found!"}</center>
          : <center>{"Loading..."}</center>}
          </div>
          </>
        )}
        {modalContent === 'Youtube' && (
          <>
          <div className="search-container">
            <input
              type="text"
              placeholder="Search..."
              className="search-input"
              onChange={handleSearchInputChange}
              onKeyDown={handleSearchOnEnter}
            />
            <Button
              className="search-button"
              onClick={() => handleSearch()}
              icon={<SearchOutlined />}
            />
          </div>
          <div>
          {!youtubeSearching?
            youtubeResults.length > 0?
            <ul className="content-list">
              {youtubeResults.map(video => (
                <li key={video.id.videoId} >
                  <img onClick={() =>handleItemClick({type :'youtube', url : `https://www.youtube.com/watch?v=${video.id.videoId}`}) } src={video.snippet.thumbnails.medium.url} alt={video.snippet.title} />
                  <span className="content-title">{video.snippet.title}</span>
                  <button className="preview-button" onClick={() => handleYoutubePreview(video)}>
                    Preview
                  </button>
                </li>
              ))}
            </ul> : <center>{"No results found!"}</center>
          : <center>{"Searching Youtube..."}</center>}
          </div>
          </>
        )}
        {/* <Tabs items={tabItems} activeKey={activeTab} onChange={handleTabChange} type="card" /> */}

      {showVideoPreviewModal && currentPreviewVideo?
        <section class="video-modal">
          <div id="video-modal-content" class="video-modal-content">
            <iframe 
                title={currentPreviewVideo.snippet.title}
                id="youtube" 
                width="100%" 
                height="100%" 
                frameborder="0" 
                allow="autoplay" 
                allowfullscreen 
                src={`https://www.youtube.com/embed/${currentPreviewVideo.id.videoId}?autoplay=1&rel=0`}
              />
              <a href="#" class="close-video-modal"><CloseOutlined onClick={handleClosePreviewModal} /></a>
          </div>
          <div class="overlay"></div>
        </section>:null}

        
          


  

      </div>
    </div>
  );
};

export default ContentModal;
