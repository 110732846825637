import React, { useState, useEffect, useRef } from 'react';
import { Switch } from 'antd';
import { AudioMutedOutlined, AudioOutlined, CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
//import 'antd/dist/antd.css'; // Import Ant Design styles
import styles from'./ParticipantList.css';

const ParticipantList = ({ 
  api,
  participants,
  setIsParticipantListOpen
  }) => {
  const handleAudioToggle = (id, status) => {
    const audioStatus = status ? 'joinAudio' : 'leaveAudio';
    api.executeCommand('sendEndpointTextMessage', id, audioStatus);
  };
  /* api.on("endpointTextMessageReceived", (message) => { console.log("ppppppppppppppppppppp", message?.data?.eventData?.text);
    if(message?.data?.eventData?.text === "leaveAudio"){
      setSoundOn(false);
      setAllVolume(0);
    }
    if(message?.data?.eventData?.text === "joinAudio"){
      setSoundOn(true);
      setAllVolume(1);
    }
  }); 

  const setAllVolume = (vol) => {
    participants.forEach(participant => {
      api.executeCommand('setParticipantVolume', participant.id, vol);
    });
  }; */

  return (
    <div className='participantListWrapper'>
      <Button
        type="text"
        icon={<CloseOutlined />}
        onClick={() => setIsParticipantListOpen(false)}
        className="closeButton"
      />
      <h4>Participant List</h4>
      <ul>
        {participants.map((participant) => (
            <li key={participant.id}>
              <span style={styles.displayName}>{participant.displayName} (ID: {participant.id})</span>
              {participant.role != "moderator" ? 
                <Switch
                  checkedChildren={<AudioOutlined />}
                  unCheckedChildren={<AudioMutedOutlined />}
                  defaultChecked
                  onChange={(checked) => handleAudioToggle(participant.id, checked)}
                  style={styles.toggle}
                /> : null
              }
            </li>
        ))}
      </ul>
    </div>
  );
};

export default ParticipantList;
